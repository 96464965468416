<template>
  <div v-cloak class="modal small" v-body-scroll-lock="true">
    <div class="modal-content">
      <div @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <div class="modal-content-section">
        <h3 class="modal-content-title" v-html="title" />
        <h4 class="modal-content-subtitle" v-html="subtitle"/>
        <div class="divider" />
        <div class="modal-content-bottom-panel">
          <button :class="buttonClass" @click="confirm">
            {{ buttonName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/libs/forms/FormGroup";
import CodeInput from "@/libs/CodeInput";

import get from 'lodash/get'
import has from 'lodash/has'
import union from 'lodash/union'

export default {
  name: 'QuestionModal',
  props: {
    submit: {
      type: Function
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    buttonName: {
      type: String,
      default: 'Confirm'
    },
    buttonClass: {
      type: Array,
      default: () => ['default-button', 'button-block']
    },
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.submit()
      setTimeout(() => this.$emit('close'), 500)
    },
  }
}
</script>

<style lang="less" scoped>
.modal {
  max-width: 530px;

  &-content {
    &-title {
      margin-bottom: 10px !important;
      line-height: 28px;
    }
    &-subtitle {
      margin: 0;
      font-size: 13px;
      line-height: 20px;
    }
    .divider {
      margin: 30px 0;
      border-bottom: 1px solid #ffffff10;
    }
  }
}

.button-block {
  display: block;
  width: 100%;
}

.button-red {
  background-color: #EA4C17;
  border-color: #EA4C17;
  color: #19191C;
}
</style>
