<template>
  <div v-if="active" id="delete-data" class="main-content">
    <tabs v-if="hasTabs" v-model="active" :items="items" />

    <div>
      <div class="panel-wrapper">
        <div>
          <h1 class="headline" :class="{ 'red-colored': active.colored }">
            {{ active.header }}
          </h1>
          <h2 class="subline">
            {{ active.text }}
          </h2>
        </div>
      </div>
      <div v-if="active.canProceed" class="inner-container">
        <div>
          <button
            class="default-button delete-data-button"
            @click="confirmDelete"
          >
            {{ active.buttonLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ConfirmModal from "../modals/ConfirmModal";
import Tabs from "@/libs/Tabs.vue";
import QuestionModal from "@/components/modals/QuestionModal.vue";

export default {
  name: "DeleteData",
  components: {Tabs},
  data() {
    return {
      active: null,
    };
  },
  mounted() {
    this.active = this.availableOptions[0] || null
  },
  computed: {
    ...mapGetters({
      companyPermissions: "companyPermissions",
      isProcessClosing: "isProcessClosing"
    }),
    ...mapState({
      user: state => state.user.data,
      company: state => state.company.data,
      settings: state => state.site.settings
    }),
    items () {
      return [
        {
          id: 0,
          type: 'user',
          label: 'Delete data',
          header: 'Delete my data',
          text: "Prior to deleting your data, we would like to inform you that by clicking the button, you will be erasing your user information from the business account. This action will include the removal of your email address and phone number, as well as logging you out of the account.",
          buttonLabel: 'Delete',
          confirmTitle: 'Do you wish to proceed with the deletion of data?',
          confirmText: 'Once deleted, you will no longer be able to access or use the account. Are you absolutely certain that you wish to proceed with deleting your user data?',
          isAvailable: this.canDeleteData,
          canProceed: true,
          colored: false
        },
        {
          id: 1,
          type: 'company',
          label: 'Close account',
          header: this.isProcessClosing ? 'The account closure is in progress…' : 'Close account',
          text: this.isProcessClosing
              ? 'We are currently working on processing your request, and it may take some time to complete your account closure. Rest assured that we are diligently working to finalise the account closure for you. We will notify you once the process is complete. We appreciate your patience and understanding.'
              : 'Before closing your account, please be aware that the closure process may take several days. During this time, you will still have access to your account and can continue to perform all actions.',
          buttonLabel: 'Close',
          confirmTitle: 'Do you wish to proceed with the closure of the account?',
          confirmText: 'We would like to remind you that upon closure, all of your data will be permanently deleted, including information about beneficiaries and other users associated with your account.',
          isAvailable: this.canCloseCompany || this.isProcessClosing,
          canProceed: this.canCloseCompany,
          colored: this.isProcessClosing
        }
      ]
    },
    canCloseCompany() {
      return this.companyPermissions?.canClose;
    },
    canDeleteData() {
      return this.user?.permissions.canUserDelete;
    },
    availableOptions() {
      return this.items.filter(item => item.isAvailable)
    },
    hasTabs() {
      return this.availableOptions.length > 1
    }
  },
  watch: {
    'items': {
      handler (newVal) {
        const updatedItem = newVal.find(item => item.id === this.active.id)
        this.active = {...this.active, ...updatedItem}
      },
      deep: true
    }
  },
  methods: {
    confirmDelete() {
      this.$modal.show(QuestionModal, {
        buttonClass: ["default-button", "button-block", "button-red"],
        title: this.active.confirmTitle,
        subtitle: this.active.confirmText,
        submit: () => {
          this.deleteData()
        }
      })
    },
    deleteData() {
      this.$modal.show(ConfirmModal, {
        buttonClass: ["default-button", "button-block", "button-red"],
        title: this.active.label,
        submit: ({ code }) => {
          let type = this.active.type;
          let data = {
            data: {
              code: code
            }
          };

          return this.$sdk[type]
            .delete(data)
            .then(response => {
              if (Array.isArray(response.data) && response.data.length === 0) {
                if (this.active.type === 'company') {
                  this.$store.dispatch("getCompany");
                  this.$notice.success(
                    "We`ve received your request and will close account soon."
                  );
                } else {
                  this.$store.dispatch("auth.logout");
                  this.$router.push("/login");
                  this.$notice.success(
                    "Thanks for using " +
                      this.settings.brand_name +
                      "! Your data has been deleted."
                  );
                }
              }

              return response;
            })
            .catch(() => {
              this.$notice.error(
                "Something was wrong. Please reload page and try again"
              );
            });
        }
      });
    },
  }
};
</script>

<style lang="less" scoped>
#delete-data {
  display: flex;
  flex-direction: column;

  .subline {
    max-width: 800px;
  }

  .delete-data-button,
  .delete-data-button:hover {
    background-color: rgb(234, 76, 22);
    border: 1px solid rgb(234, 76, 22);
  }
}
.tabs {
  margin: 30px 30px 0;
}
</style>
